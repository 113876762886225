@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px); /* Slight bounce upward */
  }
  60% {
    transform: translateY(-5px); /* Slight bounce downward */
  }
}

/* fadeInUp animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

a {
    text-decoration: none;
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  min-height: 800px;
  max-height: 80vh;
  padding: 50px;
  background: url("../../images/hero-background.jpg") no-repeat center
    center/cover;
  color: white;
  z-index: 1;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 0; 
}

.hero-content,
.hero-image-placeholder {
  position: relative;
  z-index: 1;
}

.hero-content {
  flex: 1;
}

.hero-image-placeholder {
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image-placeholder img {
  width: auto;
  height: 80vh;
  animation: fadeInUp 1.5s ease-in-out forwards, bounce 2s infinite 1.5s;

}

.hero-title {
  font-size: 3.5rem; 
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.hero-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #FFFF00; 
  margin-bottom: 30px;
  font-weight: 600;
}

.button-container {
  display: flex;
  gap: 20px; 
}

.hero-button {
  font-family: "Poppins", sans-serif;
  background-color: #ff8c00;
  color: white;
  padding: 15px 40px;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 30px;
  cursor: pointer;

  animation: fadeInUp 1.5s ease-in-out forwards, bounce 2s infinite 1.5s;
}

.hero-button:hover {
  background-color: #ff6400;
}

.telegram-button {
  font-family: "Poppins", sans-serif;
  background-color: #0088c0;
  color: white;
  padding: 15px 40px;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: fadeInUp 1.5s ease-in-out forwards, bounce 2s infinite 1.5s;
}

.telegram-button:hover {
  background-color: #007ab8; 
}

.telegram-button svg {
  font-size: 2.5rem;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-title,
.hero-subtitle {
  animation: fadeInUp 1s ease-in-out;
}

/* Scroll Down Indicator */
.scroll-down {
  position: absolute;
  bottom: 20px; /* Position at the bottom of the hero section */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #ffffff;
}

.scroll-down span {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.scroll-down-icon {
  font-size: 2.5rem; /* Size of the icon */
  animation: bounceDown 2s infinite; /* Bouncing animation */
  color: #ffffff; /* White color for the icon */
}

@keyframes bounceDown {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px); /* Slight downward movement */
  }
  60% {
    transform: translateY(5px); /* Slight upward movement */
  }
}

@media (max-width: 1000px) {
    .hero-button,.telegram-button {
        font-size: 1.1rem;
    }
}

@media (max-width: 850px) {
    .hero-button,.telegram-button {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    position: relative;
    padding: 50px 10px;
    min-height: 500px;
  }

  .hero-content {
    z-index: 2; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero-image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%; 
    z-index: 1; 
  }

  .hero-image-placeholder img {
    filter: brightness(60%);
    height: 100%; 
    object-fit: cover; 
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .button-container {
    flex-direction: column; 
    align-items: center;
  }

  .hero-button,
  .telegram-button {
    width: 100%; 
    max-width: 300px; 
    font-size: 1.5rem;
    padding: 12px 30px;
  }

  .hero-button {
    margin-bottom: 15px;
  }

  .scroll-down {
    display: none;
  }
}
