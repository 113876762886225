.header {
  width: 100%;
  position: fixed; 
  top: 0;
  z-index: 100; 
}

.navbar {
  display: flex;
  justify-content: center; 
  align-items: center;
  padding: 15px 20px;
  max-width: 1200px; 
  margin: 0 auto;
}

.nav-logo {
  width: 250px; 
  height: auto;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-logo {
    width: 180px; 
  }

  .navbar {
    padding: 10px 20px;
  }
}
