
.review-carousel-section {
    background: linear-gradient(180deg, #87CEEB 0%, #ffffff 100%);
    padding: 50px;
    text-align: center;
    color: #333;
    font-family: 'Poppins', sans-serif;
  }
  

  .review-carousel-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
    font-weight: 700;
  }
  

  .review-swiper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  

  .review-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .review-card:hover {
    transform: translateY(-5px);
  }
  
  .review-screenshot {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  

  .review-info {
    margin-top: 10px;
  }
  
  .review-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }
  
  .review-rating {
    color: #ffda44; 
    font-size: 1rem;
    margin: 5px 0;
  }
  
  .review-feedback {
    font-size: 1rem;
    color: #555;
  }
  

  @media (max-width: 768px) {
    .review-carousel-title {
      font-size: 2rem;
    }
  
    .review-card {
      max-width: 100%;
    }
  }
  