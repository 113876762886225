
.brand-showcase {
  overflow: hidden; 
  width: 100%;
  padding: 20px 0;
  background: linear-gradient(45deg, #87CEEB 0%, #ffffff 100%);
}

.brand-slider {
  display: flex;
  align-items: center;
  animation: scroll 20s linear infinite; 
}

.brand-slider img {
  width: 250px; 
  height: auto;
  margin-right: 40px; 
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .brand-slider img {
    width: 180px;
    margin-right: 20px; 
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        -200%
      ); 
    }
  }
}
