.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    position: relative;
    background: url("../../images/offer-background.jpg") no-repeat center center/cover;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .content-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
  }
  
  .main-content,
  .side-image {
    position: relative;
    z-index: 1;
  }
  
  .side-image {
    width: 500px;
    height: auto;
    max-width: 100%;
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .countdown {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .countdown .timer-title {
    font-size: 2.5rem; 
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .countdown .old-bonus {
    text-decoration: line-through;
    color: #ff9f9f;
    font-weight: 600;
  }
  
  .countdown .new-bonus {
    color: #ff6363;
  }
  
  .time {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
  }
  
  .time-number {
    background-color: #000;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .time-label {
    margin-top: 5px;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
  }
  
  .pricing-box {
    background-color: #fff;
    color: #000;
    padding: 30px 20px;
    border-radius: 15px;
    width: 350px;
    text-align: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    font-family: "Poppins", sans-serif;
  }
  
  .price {
    font-size: 2rem; 
    font-weight: bold;
    margin: 20px 0;
  }
  
  .signup-button {
    background-color: #ffda44;
    color: #000;
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 30px;
    transition: background-color 0.3s ease, transform 0.2s ease-in-out;
  }
  
  .signup-button:hover {
    background-color: #ffd700;
    transform: scale(1.05);
  }
  
  .features-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .features-list li {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .features-list li::before {
    content: "✔";
    color: #ffda44;
    margin-right: 10px;
  }
  
  @media (max-width: 1080px) {
    .side-image {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
      padding: 20px;
    }
  
    .pricing-box {
      width: 100%;
      padding: 30px 15px;
    }
  
    .price {
      font-size: 1.8rem;
    }
  
    .signup-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 550px) {
    .content-container {
      padding: 15px; 
    }
  
    .pricing-box {
      width: 90%; 
    }
  
    .price {
      font-size: 1.6rem; 
    }
  
    .signup-button {
      font-size: 1rem;
      padding: 10px 20px; 
    }
  
    .countdown .timer-title {
      font-size: 2rem; 
    }
  
    .time-number {
      font-size: 1rem; 
      padding: 8px 12px;
    }
  
    .time-label {
      font-size: 0.9rem;
    }
  }
  